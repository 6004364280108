<template>
  <div>
   <Top  v-if="$route.path !== '/cerdetail'"/>
   <div id="app">
    <router-view />
   </div>
   <Footer v-if="$route.path !== '/cerdetail'" />
  </div>
</template>

<script >
import Top from '@/components/top'
import Footer from '@/components/footer'
export default{
   components:{
    Top,
    Footer
    
   }
}
</script>

<style lang="scss">
 .el-pager li{
  line-height: 28px !important;
 }
 #app{
  min-height: 90vh;
 }
</style>
